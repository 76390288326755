<!--学员信息  -->
<template>
    <div class="StudentInfo">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">学员信息</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <div class="" style="margin-left: 21px;">
            <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                @click="refresh">
                <!-- 刷新 -->
            </el-button>
            <el-button size="small" icon="el-icon-plus" v-if="tab==2" 
            style="background: #18BC9C;color: #FFFFFF;" @click="newlyAdded">
                新增
            </el-button>
        </div>
        <!-- tab切换 -->
        <div class="" style="margin-top: 20px;">
            <button class="btn" type="button" v-for="(item,index) in tabBtn" :key="index"
             @click="toTab(index)" :class="tab==index?'active':''">
                {{item}}
            </button>
        </div>
        <!-- 学员 表格 -->
        <div class="" v-if="tab==0" :key="0">
            <el-table :data="studentData" border stripe style="width:97.8%;flex: 1;" height="620">
                <!-- 复选按钮 -->
                <el-table-column type="selection" align="center" width="50">
                </el-table-column>
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" sortable label="学生号" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="学生姓名" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="报名日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" sortable label="交费日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" sortable label="退(转)学日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" sortable label="学籍类" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="说明" align="center" width="200">
                </el-table-column>
                <el-table-column prop="" sortable label="优惠券" align="center"  width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="让利" align="center" width="100">
                </el-table-column>
                <el-table-column prop="" sortable label="应收学杂费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="其中学费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="杂费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="实收金额" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="定金" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="报名上课次数" align="center" width="130">
                </el-table-column>
                <el-table-column prop="" sortable label="退(转)费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="学费标准" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="授课次数" align="center" width="120">
                </el-table-column>
            </el-table>
        </div>
        <!-- 授课老师 表格-->
        <div class="" v-if="tab==1" :key="1">
            <el-table :data="teacherData" border stripe style="width:97.8%;flex: 1;" height="620">
                <el-table-column prop="" label="序号"  align="center" width="80">
                </el-table-column>
                <el-table-column prop="" label="负责状态" sortable align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="优先级" sortable align="center" width="100">
                </el-table-column>
                <el-table-column prop="" label="授课老师" sortable align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="协助比例(%)" sortable align="center" width="130">
                </el-table-column>
                <el-table-column prop="" label="培训课" sortable align="center" width="200">
                </el-table-column>
                <el-table-column prop="" label="设置人" sortable align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="设置日期" sortable align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="备注" sortable align="center" width="300">
                </el-table-column>
            </el-table>
        </div>
        <!-- 排课表 -->
        <div class="" v-if="tab==2" :key="2">
            <el-table :data="timeData" border stripe style="width:97.8%;flex: 1;" height="620">
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="course_type" sortable label="课类" align="center" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.course_type==1">正常课</span>
                        <span v-else-if="scope.row.course_type==2">补课</span>
                    </template>
                </el-table-column>
                <el-table-column prop="course_status" sortable label="状态" align="center" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.course_status==1">可用</span>
                        <span v-else-if="scope.row.course_status==2">取消</span>
                        <span v-else-if="scope.row.course_status==3">已授课</span>
                    </template>
                </el-table-column>
                <el-table-column prop="abstract" sortable label="排课摘要" align="center" width="150">
                </el-table-column>
                <el-table-column prop="number" sortable label="培训课码" align="center" width="150">
                </el-table-column>
                <el-table-column prop="class_name" sortable label="培训班名称" align="center" width="320">
                </el-table-column>
                <el-table-column prop="course_date" sortable label="上课日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="course_time" sortable label="上课时间" align="center" width="150">
                </el-table-column>
                <el-table-column prop="course_duration" sortable label="上课时长(hr)" align="center" width="150">
                </el-table-column>
                <el-table-column prop="remark" sortable label="备注说明" align="center" width="300">
                </el-table-column>
                <el-table-column  label="操作" align="center"  fixed="right" width="200">
                    <template slot-scope="scope">
                        <el-button icon="el-icon-edit" size="mini" style="background:#18BC9C;color: #fff;" 
                         @click="edit(scope.$index, scope.row)">
                            编辑
                        </el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini" 
                        @click="delt(scope.$index, scope.row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 签到主表 -->
        <div class="" v-if="tab==3" :key="3">
            <el-table :data="signData" border stripe style="width:97.8%;flex: 1;" height="620">
                <el-table-column prop="" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" sortable label="审核状态" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="单号" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="班级编号" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="班级名称" align="center" width="280">
                </el-table-column>
                <el-table-column prop="" sortable label="授课老师" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="课次" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="上课日期" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="上课时间" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="本班人数" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="签到人数" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="试学人数" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="补课人数" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="出勤率(%)" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="工作量-1" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="工作量-2" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="上课教室" align="center" width="120">
                </el-table-column>
            </el-table>
        </div>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" 
             @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total" v-if="tab!==1">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               tabBtn:["学员","授课老师","排课表","签到主表"],
               tab:0,//代表下标
               studentData:[],//学员表格数据
               teacherData:[],//授课老师数据
               timeData:[],//排课表
               signData:[],//签到主表
               currentPage: 1,
               currentLimit: 20, //条数
               total: 0,
            }
        },
        created() {
            this.getList()
        },
        activated() {
            this.getList()
        },
        methods:{
            getList(){
                if(this.tab==0){//学员
                    this.$request({
                        url:'',
                        method:'POST',
                        data:{
                            page:this.currentPage,
                            limit:this.currentLimit
                        }
                    }).then(res=>{
                        if(res.code==1){
                            console.log(res)
                            // this.studentData=res.data.list
                        }
                    })
                }else if(this.tab==1){//授课老师
                    this.$request({
                        url:'',
                        method:'POST',
                        data:{
                            page:this.currentPage,
                            limit:this.currentLimit
                        }
                    }).then(res=>{
                        if(res.code==1){
                            console.log(res)
                            // this.teacherData=res.data.list
                        }
                    })
                }else if(this.tab==2){//排课表
                    this.$request({
                        url:'/api/class_/scheduleList',
                        method:'POST',
                        data:{
                            class_id:this.$route.query.id,
                            page:this.currentPage,
                            limit:this.currentLimit
                        }
                    }).then(res=>{
                        if(res.code==1){
                            console.log(res,'排课表')
                            this.timeData=res.data.list
                            this.total=res.data.count
                        }
                    })
                }else if(this.tab==3){//签到主表
                    this.$request({
                        url:'',
                        method:'POST',
                        data:{
                            page:this.currentPage,
                            limit:this.currentLimit
                        }
                    }).then(res=>{
                        if(res.code==1){
                            console.log(res)
                            // this.studentData=res.data.list
                        }
                    })
                }
            },
            toTab(index){
                this.tab=index
                this.getList()
            },
            goBack() { //返回
                this.$router.go(-1);
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            newlyAdded() { //新增
                if(this.tab==2){
                    this.$router.push({
                        path: "addTimetable",
                        query:{
                            id:this.$route.query.id
                        }
                    })
                }
            },
            edit(index,row){//编辑
                this.$router.push({
                    path: "editTimetable",
                    query:{
                        id:row.id
                    }
                })
            },
            delt(index,row){//删除
                this.$confirm('是否删除此条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$request({
                        url: '/api/class_/scheduleDel',
                        method: 'POST',
                        data: {
                            id: row.id
                        }
                    }).then(res => {
                        if (res.code == 1) {
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.timeData.splice(index, 1)
                            this.getList()
                        }
                    })
                })
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
            },
        }
    }
</script>

<style scoped="scoped">
    .StudentInfo{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 刷新按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    
    /* 新增按钮 */
    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }
    /* tab按钮 */
    .btn{
       display: inline-block;
       border: 1px solid #999999;
       background-color: #FFFFFF;
       padding: 5px 15px;
       margin-left: 21px;
       font-size: 14px;
       border-radius: 3px;
       cursor: pointer;
    }
    /* 选中样式 */
    .active{
        background:#CDEEEB;
        border: 1px solid #579490;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }
    /* 分页 */
    .el-pagination {
        margin:20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>
